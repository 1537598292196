<template>
  <div class="tab-navigator row">
    <ul class="tabs">
      <li
        v-for="(tab, index) in tabs"
        :key="`tabNav_${index}`"
        :class="{ active: currentTab === tab.id }"
        @click="selectTab(tab.id, tab.title)"
      >
        {{ tab.title }}
      </li>
    </ul>
    <div class="tab-navigator__buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import isEqual from 'lodash/isEqual'
export default {
  name: 'TabNavigator',
  props: {
    tabs: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('tabNavigator', ['currentTab'])
  },
  methods: {
    ...mapActions('tabNavigator', ['setCurrentTab']),
    selectTab (tabId, tabTitle) {
      const payload = {
        id: tabId,
        title: tabTitle
      }
      this.setCurrentTab(payload)
      this.$emit('tabWasChanged')
    },
    initializeFirstTab () {
      const currentTabExists = this.tabs.some(tab => tab.id === this.currentTab)
      if (!currentTabExists && this.tabs.length > 0) {
        this.selectTab(this.tabs[0].id, this.tabs[0].title)
      }
    }
  },
  mounted () {
    this.initializeFirstTab()
  },
  watch: {
    tabs: {
      handler (newVal, oldVal) {
        if (oldVal !== undefined && !isEqual(newVal, oldVal)) {
          this.initializeFirstTab()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>

.tab-navigator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 1px 0 5px rgba(0, 0, 0, .1);
  padding: 0.625rem;
  margin-bottom: 1rem;
}

.tabs {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
  padding-left: 1rem;
  li {
    position: relative;
    list-style: none;
    padding: 0.75rem 1rem;
    cursor: pointer;

    &.active {
      color: #000;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background-color: #6599FE;
        border-radius: 5px;
      }
    }
  }
}

.tab-navigator__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
